<template>
  <div class="vlive-errors">
    <p>{{message}}</p>
  </div>
</template>

<script>
export default {
  props: ["message"],
  data() {
    return {};
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./ErrorAlert.scss"
</style>